<template>
  <en-drawer :model-value="modelValue" :title="`${form.data.id ? '编辑' : '新建'}${title}`" @close="$emit('update:model-value', false)">
    <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')">
      <en-form-item label="客户名称" prop="client">
        <select-maintain
          v-model="form.data.client"
          :ajax="{
            action: 'GET /enocloud/common/customer',
            params: (params, value) => (params.payload = { name: value, overdueCode: 'N' })
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          :disabled="form.disabled"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="结算方式" v-if="code === 'SAL' || code == 'SALR'" prop="settlingAccountMethod">
        <select-maintain
          v-model="form.data.settlingAccountMethod"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['STLACMTD']) }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="运费支付" prop="freightPaymentMethod" v-if="code === 'SAL' || code === 'SALR'">
        <select-maintain
          v-model="form.data.freightPaymentMethod"
          :ajax="{
            action: 'GET /enocloud/common/lookup/:lookupType',
            params: (params) => (params.paths = ['FRPMMTD'])
          }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          :disabled="form.disabled"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="物流公司" prop="logisticsCompany" v-if="code === 'SAL' &&form.data.freightPaymentMethod?.code!=='N'">
        <select-maintain
          v-model="form.data.logisticsCompany"
          :ajax="{
            action: 'GET /enocloud/common/customer',
            params: (params, value) => (params.payload = { name: value, type: 'L', rsexp: 'id,name', pagingEnabled: false })
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          :disabled="form.disabled"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="物流公司" prop="logisticsCompany" v-if=" code === 'SALR'&&form.data.freightPaymentMethod?.code!=='N'">
        <select-maintain
          v-model="form.data.logisticsCompany"
          :ajax="{
            action: 'GET /enocloud/common/customer',
            params: (params, value) => (params.payload = { name: value, type: 'L', rsexp: 'id,name', pagingEnabled: false })
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          :disabled="form.disabled"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="物流费用" prop="freight" v-if="code === 'SAL'&&form.data.freightPaymentMethod?.code!=='N'">
        <en-input-number v-model="form.data.freight" :disabled="form.disabled" class="w-full"></en-input-number>
      </en-form-item>
      <en-form-item label="物流费用" prop="freight" v-if="code === 'SALR'&&form.data.freightPaymentMethod?.code!=='N'">
        <en-input-number v-model="form.data.freight" :disabled="form.disabled" class="w-full"></en-input-number>
      </en-form-item>
      <en-form-item label="发票类型" v-if="code === 'SAL' || code === 'SALR'">
        <select-maintain
          v-model="form.data.invoiceTypeName"
          :ajax="{
            action: 'GET /enocloud/common/hint/:hintTypeStr',
            params: (params) => (params.paths = ['INVCTP'])
          }"
          :props="{ label: 'name', value: 'name' }"
          :disabled="form.disabled"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="销售类型" v-if="code === 'SAL'">
        <select-maintain
          v-model="form.data.otherType"
          :ajax="{
            action: 'GET /enocloud/common/hint/:hintTypeStr',
            params: (params) => (params.paths = ['SALETYPE'])
          }"
          :props="{ label: 'name', value: 'name' }"
          :disabled="form.disabled"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="其他费用" prop="otherCharge" v-if="code === 'SAL' || code === 'SALR'">
        <en-input-number v-model="form.data.otherCharge" :disabled="form.disabled" class="w-full"></en-input-number>
      </en-form-item>
      <en-form-item label="出货类型" v-if="code === 'OTH'">
        <select-maintain
          v-model="form.data.otherType"
          :ajax="{
            action: 'GET /enocloud/common/hint/:hintTypeStr',
            params: (params) => (params.paths = ['OSALTYPE'])
          }"
          :props="{ label: 'name', value: 'name' }"
          :disabled="form.disabled"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="备注">
        <en-input type="textarea" v-model="form.data.comment" :disabled="form.disabled"></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
import { calculator } from '@enocloud/utils'

export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudInventoryDefinitions['StockTransferOutDto']>,
    code: String as PropType<'SAL' | 'SALR' | 'OTH' | 'TRA'>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: (id: number | undefined) => ['number', 'undefinded'].includes(typeof id)
  },

  watch: {
    modelValue: {
      async handler() {
        if (this.modelValue) {
          if (this.data?.id) {
            Object.assign(this.form.data, this.data)
            this.form.get()
          }
        } else {
          this.form.init()
        }
      }
    }
  },

  computed: {
    title() {
      switch (this.code) {
        case 'SAL':
          return '销售单'
        case 'SALR':
          return '销退单'
        case 'OTH':
          return '其他出货单'
        case 'TRA':
          return '移库出库单'
      }
    }
  },

  config: {
    children: {
      form: {
        data: {
          type: { code: '' },
          settlingAccountMethod: {
          code: 'CASH',
          message: '',
          type: ''
        },
        freightPaymentMethod: {
          code: 'N',
          message: '',
          type: ''
        },
          saleGoods: [] as EnocloudInventoryDefinitions['SaleGoodsDto'][]
        },
        rules: {
          logisticsCompany: [{ required: true, message: '请选择物流公司' }],
          freight: [{ required: true, message: '请选择物流费用' }],
          freightPaymentMethod: [{ required: true, message: '请选择运费支付方式' }],
          settlingAccountMethod: [{ required: true, message: '请选择结算方式' }],
          otherType: [{ required: true, message: '请选择出货类型' }],
          client: [{ required: true, message: '请选择客户类型' }]
        },
        ajax: {
          get: {
            action: 'GET /enocloud/sale/:saleId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'POST /enocloud/sale',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          update: {
            action: 'PUT /enocloud/sale',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        },
        computed: {
          disabled() {
            return this.form.data.status?.code === 'A' || this.form.data.status?.code === 'S'
          }
        },

        children: {
          supplier: {
            async change() {
              if (this.form.data.auditBy?.id) {
                const res = await this.ajax('GET /enocloud/purchase/:supplierId/supplierrecent', { paths: [this.form.data.auditBy.id] })
                this.form.data.warehouse = res.data[0]?.warehouse
                this.form.data.branch = res.data[0]?.warehouse?.branch
                this.form.data.invoiceTypeName = res.data[0]?.invoiceTypeName
                this.form.data.logisticsCompany = res.data[0]?.logisticsCompany
                this.form.data.freightPaymentMethod = res.data[0]?.freightPaymentMethod
              }
            }
          },
          warehouse: {
            change() {
              this.form.data.branch = this.form.data.warehouse?.branch
            }
          },
          taxRatePercent: {
            change() {
              for (const item of this.form.data.saleGoods ?? []) {
                item.preTaxPrice = calculator.div(item.price ?? 0, calculator.add(1, this.form.data.taxRate))
                item.preTaxAmount = calculator.mul(item.preTaxPrice, item.count)
              }
            }
          }
        }
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        },
        confirm: {
          async click() {

            const res = await this.form[this.form.data.id ? 'update' : 'submit']()
            this.emit('update:model-value', false)
            this.emit('confirm', (res.data[0] as number | undefined) || this.form.data.id)
          }
        }
      }
    }
  },

})
</script>
